<template>
  <div>
    <b-card>
      <b-form
        v-if="show"
        @submit="onSubmit"
        @reset="onReset"
      >
        <b-form-group
          id="input-group-1"
          label="Ý kiến xử lý"
          label-for="input-1"
          description=""
        >
          <b-form-input
            id="input-1"
            v-model="form.email"
            type="email"
            placeholder="Nhập ý kiến xử lý"
            required
          />
        </b-form-group>
        <b-row class="pt-1">
          <b-col>
            <div class="d-sm-flex justify-content-end">
              <button class="compact ui btn-primary button">
                Tải file đính kèm
              </button>
            </div>
          </b-col>
        </b-row>
        <b-row class="pt-1">
          <b-col>
            <b-form-group
              id="input-group-5"
              label="Đề xuất xử lý"
              label-for="input-5"
            >
              <b-form-select
                id="input-5"
                v-model="form.food"
                :options="dataDonVi"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <div>
              <b-form-group
                id="input-group-6"
                label="Người nhận xử lý"
                label-for="input-6"
              >
                <b-form-select
                  id="input-3"
                  v-model="form.food"
                  :options="dataDonVi"
                />
              </b-form-group>
            </div>
          </b-col>
        </b-row>
        <div class="d-sm-flex justify-content-end">
          <b-button
            type="submit"
            variant="primary"
            class="mr-1"
          >
            Đồng ý
          </b-button>
          <b-button
            type="reset"
            variant="danger"
          >
            Quay lại
          </b-button>
        </div>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BCard,
  BButton,
  BRow,
  BCol,
  BFormSelect,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BRow,
    BCol,
    BFormSelect,
  },
  data() {
    return {
      form: {
        email: '',
        name: '',
        food: null,
        checked: [],
      },
      dataDonVi: [
        {
          id: 1,
          text: 'đơn vị 1',
          value: '1',
        },
        {
          id: 2,
          text: 'đơn vị 2',
          value: '2',
        },
        {
          id: 3,
          text: 'đơn vị 3',
          value: '3',
        },
      ],
      show: true,
    }
  },
  methods: {
    onSubmit(event) {
      event.preventDefault()
      // eslint-disable-next-line no-alert
      alert(JSON.stringify(this.form))
    },
    onReset(event) {
      event.preventDefault()
      // Reset our form values
      this.form.email = ''
      this.form.name = ''
      this.form.food = null
      this.form.checked = []
      // Trick to reset/clear native browser form validation state
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.customName {
  max-width: 100%;
  height: 40px;
  background-color: #043f6f;
  color: #ffffff;
}
</style>
